<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'

const props = defineProps<{
  item: ParsedContent
}>()

const subtitle = computed(() => {
  const doc = props.item
  if (!doc) return null
  const category = doc.category || []
  const prereq = doc.prereq ? ` (Prerequisite: ${doc.prereq})` : ''
  return `${category.includes('origin') ? 'Origin' : 'General'} feat${prereq}`
})
</script>

<template>
  <p class="subtitle subtitle--feat">
    {{ subtitle }}
  </p>
</template>

<style lang="postcss" scoped></style>
